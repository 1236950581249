
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  ref,
  ComputedRef,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import NewClientModal from "@/components/modals/forms/NewClientModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { Client } from "../../../core/types/ClientTypes";

export default defineComponent({
  name: "lda-clients",
  components: {
    TableSkeleton,
    NewClientModal,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const route = useRoute();
    const routeName = computed(() => route.name);
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const clients = computed(() => {
      return store.getters.getClientList;
    });
    const searchClients = ref("");
    const distAspNetUserId = computed(() => {
      return store.getters.getLDAssociatesLicensedDistAspNetUserId;
    });

    onMounted(() => {
      setCurrentPageTitle("Clients");
      setCurrentPageBreadcrumbs("Client List", ["Clients"]);
    });

    store
      .dispatch(
        Actions.GET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID,
        currentUser.id
      )
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving LicensedDistId",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    store
      .dispatch(Actions.GET_LICENSED_DIST_ASSOCIATE_CLIENTS, currentUser.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving clients.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const filterClients: ComputedRef<Array<Client>> = computed(() => {
      return clients.value.filter((client) => {
        return (
          client.name
            .toLowerCase()
            .indexOf(searchClients.value.toLowerCase()) != -1
        );
      });
    });

    // watch(
    //   [clients, distAspNetUserId],
    //   () => {
    //     isDataReady.value = true;
    //   },
    //   { immediate: true, deep: true }
    // );

    watch(clients, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      routeName,
      isDataReady,
      clients,
      searchClients,
      filterClients,
      displayModal,
      distAspNetUserId,
    };
  },
});
